import { del, get, HttpResponse, patch } from '@/api/Api';
import { THGUser } from '@/interfaces/thg/THGUser';
import { TokenType } from '@/enums/TokenType';
import { THGUserPaginatedResponse } from '@/interfaces/thg/THGUserPaginatedResponse';
import { THGUserFilterParam } from '@/interfaces/thg/THGUserFilterParam';
import { THGUserUpdateData } from '@/interfaces/thg/THGUserUpdateData';
import { api_url } from '@/api/thg/api';
import { SortOrder } from '@/enums/misc';
import { THGTotalUserCarsCountResponse } from '@/interfaces/thg/THGTotalUserCarsCountResponse';
import { formatDate } from '@/utils/misc';

/**
 * Get a user by its ID from thg api.
 *
 * @param userId user ID
 * @return http response to await
 */
export async function getTHGUser(userId: string): Promise<HttpResponse<THGUser>> {
    return await get<THGUser>(`${api_url()}/crm/users/${userId}`, TokenType.THG_API_KEY);
}

/**
 * Delete a user and its cars by calling the THG API CRM endpoint.
 *
 * @param userId user id
 * @return http response to await
 */
export async function deleteTHGUser(userId: string): Promise<HttpResponse<null>> {
    return await del<null>(`${api_url()}/crm/users/${userId}`, null, TokenType.THG_API_KEY);
}

export async function saveTHGUser(
    userId: string,
    user: THGUserUpdateData,
): Promise<HttpResponse<null>> {
    return await patch<null>(`${api_url()}/crm/users/${userId}`, user, TokenType.THG_API_KEY);
}

/**
 * Get the total number of cars linked to given user query.
 *
 * @param filterParams
 */
export async function countTotalCars(
    filterParams?: THGUserFilterParam,
): Promise<HttpResponse<THGTotalUserCarsCountResponse>> {
    const params = buildTHGUserParams(filterParams);

    return await get<THGTotalUserCarsCountResponse>(
        `${api_url()}/crm/users/count/total-cars?${params}`,
        TokenType.THG_API_KEY,
    );
}

export function buildTHGUserParams(
    filterParams?: THGUserFilterParam,
    pageSize?: number,
    pageNum?: number,
    sortField?: string,
    sortOrder?: SortOrder,
): URLSearchParams {
    const params = new URLSearchParams();

    if (pageSize) {
        params.set('page_size', pageSize.toString());
    }

    if (pageNum) {
        params.set('page_num', pageNum.toString());
    }

    if (sortField) {
        params.set('sort_field', sortField);

        if (sortOrder) {
            params.set('sort_order', sortOrder.toString());
        }
    }

    if (filterParams) {
        if (filterParams.is_verified !== undefined && filterParams.is_verified !== null) {
            params.set('is_verified', filterParams.is_verified ? '1' : '0');
        }

        if (filterParams.is_active !== undefined && filterParams.is_active !== null) {
            params.set('is_active', filterParams.is_active ? '1' : '0');
        }

        if (filterParams.type) {
            params.set('type', filterParams.type);
        }

        if (filterParams.email) {
            params.set('email', filterParams.email);
        }

        if (filterParams.name) {
            params.set('name', filterParams.name);
        }

        if (filterParams.start_create_date && filterParams.end_create_date) {
            const startCreateDateString = formatDate(filterParams.start_create_date, 'yyyy-MM-dd');
            params.set('start_create_date', startCreateDateString);

            const endCreateDateString = formatDate(filterParams.end_create_date, 'yyyy-MM-dd');
            params.set('end_create_date', endCreateDateString);
        }

        if (filterParams.phone_number) {
            params.set('phone_number', filterParams.phone_number);
        }

        if (filterParams.vat_number) {
            params.set('vat_number', filterParams.vat_number);
        }

        if (filterParams.is_invalid_vat_number) {
            params.set('is_invalid_vat_number', '1');
        }

        if (
            filterParams.min_donation_ratio !== undefined &&
            filterParams.min_donation_ratio !== null
        ) {
            params.set('min_donation_ratio', filterParams.min_donation_ratio.toString());
        }

        if (
            filterParams.max_donation_ratio !== undefined &&
            filterParams.max_donation_ratio !== null
        ) {
            params.set('max_donation_ratio', filterParams.max_donation_ratio.toString());
        }

        if (filterParams.zip_code) {
            params.set('zip_code', filterParams.zip_code);
        }

        if (filterParams.city) {
            params.set('city', filterParams.city);
        }

        if (filterParams.street) {
            params.set('street', filterParams.street);
        }

        if (filterParams.min_cars_count !== undefined && filterParams.min_cars_count !== null) {
            params.set('min_cars_count', filterParams.min_cars_count.toString());
        }

        if (filterParams.max_cars_count !== undefined && filterParams.max_cars_count !== null) {
            params.set('max_cars_count', filterParams.max_cars_count.toString());
        }

        if (filterParams.car_year) {
            params.set('car_year', filterParams.car_year.toString());
        }
    }

    return params;
}

/**
 * Fetch THG Users in a paginated way (optional).
 *
 * @param pageSize
 * @param pageNum
 * @param sortField
 * @param sortOrder
 * @param filterParams
 */
export async function fetchTHGUsers(
    filterParams?: THGUserFilterParam,
    pageSize?: number,
    pageNum?: number,
    sortField?: string,
    sortOrder?: SortOrder,
): Promise<HttpResponse<THGUserPaginatedResponse | THGUser[]>> {
    const params = buildTHGUserParams(filterParams, pageSize, pageNum, sortField, sortOrder);

    return await get<THGUserPaginatedResponse | THGUser[]>(
        `${api_url()}/crm/users?${params}`,
        TokenType.THG_API_KEY,
    );
}
